<template>
    <div>
        <v-card class="cardClass" flat v-if="this.patientDetails">
            <div class="patinetImgName d-flex">
                <div>
                    <img src="https://img.icons8.com/color/50/000000/user.png">
                </div>
                <div class="mt-1" style="display: flex; flex-direction: column; padding: 2px;">
                    <div>
                        <h6 class="patientNameClass" align="left">
                            Patient Name
                        </h6>
                    </div>
                    <div v-if="this.patientDetails.customer_name ">
                        <p style="margin-top: 2px; font-weight: 700">
                            {{ patientDetails.customer_name }}
                        </p>
                    </div>
                    <div v-else>
                        <p style="margin-top: 2px; font-weight: 700" >
                            {{ patientDetails.dependent_name }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="flex-column justify-space-around">
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/calendar.svg">
                        <p class="ml-2 mb-0 filedHeading">Age: </p>
                        <p class="patientValue">{{ age }}</p>
                    </div>
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/height.svg">
                        <p class="ml-2 mb-0 filedHeading">Height: </p>
                        <p class="patientValue" v-if="patientDetails.customer_height">{{ patientDetails.customer_height }}</p>
                        <p class="patientValue" v-else>{{ patientDetails.dependent_height }}</p>
                    </div>
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/calendar.svg">
                        <p class="ml-2 mb-0 filedHeading">Alcoholic: </p>
                        <p class="patientValue" v-if="patientDetails.alcoholic">{{ patientDetails.alcoholic }}</p>
                        <p class="patientValue" v-else>{{ patientDetails.dependent_alcoholic }}</p>
                    </div>
                </div>

                <div class="flex-column patientDetails">
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/gender.svg">
                        <p class="ml-2 mb-0 filedHeading">Gender: </p>
                        <p class="patientValue" v-if="patientDetails.customer_gender">{{ patientDetails.customer_gender }}</p>
                        <p class="patientValue" v-else>{{ patientDetails.dependent_gender }}</p>
                    </div>
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/weight.svg">
                        <p class="ml-2 mb-0 filedHeading">Weight: </p>
                        <p class="patientValue" v-if="patientDetails.customer_weight">{{ patientDetails.customer_weight }}</p>
                        <p class="patientValue" v-else>{{ patientDetails.dependent_weight }}</p>
                    </div>
                    <div class="d-flex patientFiledClass pl-2">
                        <img src="https://s3iconimages.mymedicine.com.mm/height.svg">
                        <p class="ml-2 mb-0 filedHeading">Smoker: </p>
                        <p class="patientValue" v-if="patientDetails.smoker">{{ patientDetails.smoker }}</p>
                        <p class="patientValue" v-else>{{ patientDetails.dependent_smoker }}</p>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "doctorViewPatientCard",
    data(){
        return{
            age:0,
        }
    },
    props:['patientDetails'],
    watch:{
        patientDetails: function() {
            if(this.patientDetails.dependent_dob){
                this.age = this.getAge(this.patientDetails.dependent_dob);
                console.log('get age depnedent', this.patientDetails.dependent_dob)
            } else {
                this.age = this.getAge(this.patientDetails.customer_dob);
                console.log('get age customer', this.patientDetails.customer_dob)
            }
        }
    },
    mounted(){
        
    },
    methods:{
        getAge(dateString) {
            console.log('dateString', dateString)
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            console.log(age, birthDate);
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log(age);
            return Number(age);
        },
    }
}
</script>
<style scoped>
.cardClass{
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.patinetImgName{
    flex-direction: row;
}
.patientNameClass{
    margin-top: 0px;
    color: #828282 !important
}
.patientFiledClass{
    align-items: flex-start;
}
.filedHeading{
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    line-height: 23px;
    margin-bottom: 16px !important;
}
.patientValue{
    text-indent: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 16px !important;
}
.patientDetails{
    margin: 0 auto;
}
</style>